import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import { authActions } from '../../containers/Auth';
import { multiFactorAuthActions } from '../../containers/MultiFactorAuth';
import { userActions } from '../../containers/User';
import PasswordResetForm from './PasswordResetForm';
import ConfirmationForm from './ConfirmationForm';
import BorderedPaper from '../branded/BorderedPaper';

const PasswordResetManager = ({
	t,
	isResettingPassword,
	hasCompletedPasswordReset,
	resetPassword,
	passwordResetError,
	deAuthUser,
	mfaLogout,
	clearUser
}) => {
	const { state } = useLocation();

	const [temporaryPassword, setTemporaryPassword] = React.useState('');
	const [newUserPassword, setNewUserPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
	const [doPasswordsMatch, setDoPasswordsMatch] = React.useState(true);

	const navigate = useNavigate();

	const deAuthorizeUser = () => {
		Auth.signOut();
		mfaLogout();
		deAuthUser();
		clearUser();

		navigate('/login');
	};

	React.useEffect(() => {
		if (!state?.username) {
			deAuthorizeUser();
		}
	}, [state]);

	const onSubmitPasswordReset = () => {
		if (newUserPassword !== confirmNewPassword) {
			setDoPasswordsMatch(false);
		} else {
			setDoPasswordsMatch(true);

			resetPassword({
				password: newUserPassword,
				code: temporaryPassword,
				username: state.username
			});
		}
	};

	return (
		<BorderedPaper>
			{!hasCompletedPasswordReset ? (
				<PasswordResetForm
					doPasswordsMatch={doPasswordsMatch}
					passwordResetError={passwordResetError}
					isResettingPassword={isResettingPassword}
					temporaryPassword={temporaryPassword}
					setTemporaryPassword={setTemporaryPassword}
					newUserPassword={newUserPassword}
					setNewUserPassword={setNewUserPassword}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={setConfirmNewPassword}
					onSubmitPasswordReset={onSubmitPasswordReset}
					deAuthUser={deAuthorizeUser}
				/>
			) : (
				<ConfirmationForm deAuthUser={deAuthorizeUser} />
			)}
		</BorderedPaper>
	);
};

PasswordResetManager.propTypes = {
	isResettingPassword: PropTypes.bool,
	hasCompletedPasswordReset: PropTypes.bool,
	passwordResetError: PropTypes.string,
	resetPassword: PropTypes.func,
	deAuthUser: PropTypes.func,
	mfaLogout: PropTypes.func,
	clearUser: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isResettingPassword: state.authReducer.getIn([
			'passwordReset',
			'isResettingPassword'
		]),
		hasCompletedPasswordReset: state.authReducer.getIn([
			'passwordReset',
			'hasCompletedPasswordReset'
		]),
		passwordResetError: state.authReducer.getIn([
			'passwordReset',
			'passwordResetError'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetPassword: (payload) => {
			dispatch(authActions.resetPassword(payload));
		},
		deAuthUser: (payload) => {
			dispatch(authActions.deAuthUser(payload));
		},
		mfaLogout: () => {
			dispatch(multiFactorAuthActions.logout());
		},
		clearUser: () => {
			dispatch(userActions.logout());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('registration')(PasswordResetManager));
