import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import shortUuid from 'short-uuid';
import NotificationUpdateTypeSelect from '../../notificationDetail/NotificationUpdateTypeSelect';
import ContainedLoadingButton from '../../buttons/ContainedLoadingButton';
import fileUploadHandler from '../../../utilities/fileUploadHandler';
import ErrorIndicator from '../../errors/ErrorIndicator';
import Restricted from '../../../permissionProvider/Restricted';
import { notificationStatusesNamed } from '../../../constants/notificationStatuses';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';

import {
	addNotificationTimelineEvent,
	resetAddNotificationTimelineEvent
} from '../../../containers/notifications/notificationsSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// import AttachmentIcon from '@mui/icons-material/Attachment';

const UploadBox = styled(Box)({
	height: 150,
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center'
});

const _createNewDetail = (message, detailType, attachments, userId) => {
	return {
		id: shortUuid.uuid(),
		message,
		createdByUserId: userId,
		createdOn: dayjs().utc().format(),
		detailType,
		triggerId: '',
		tags: [],
		attachments: attachments.map((attachmentData) => ({
			id: shortUuid.uuid(),
			assetData: attachmentData,
			type: 'image',
			label: ''
		})),
		suggestedActions: []
	};
};

const NotificationInformation = ({ t }) => {
	const dispatch = useDispatch();
	const { notificationId } = useParams();

	const [updateMessage, setUpdateMessage] = React.useState('');
	const [selectedUpdateType, setSelectedUpdateType] = React.useState('');
	const [attachmentsList, setAttachmentsList] = React.useState([]);
	const [fileReadyToUpload, setFileReadyToUpload] = React.useState(false);

	const {
		data: notificationDetail,
		isLoading: isLoadingNotificationDetail,
		hasBeenLoaded: hasLoadedNotificationDetail,
		error: notificationDetailError
	} = useSelector((state) => state.notificationsSlice.getNotification);
	const {
		isLoading: isAddingNotificationTimelineEvent,
		hasCompleted: succesfullyAddedNotificationTimelineEvent,
		error: addNotificationTimelineEventError
	} = useSelector(
		(state) => state.notificationsSlice.addNotificationTimelineEvent
	);
	const userReducer = useSelector((state) => state.userReducer);

	React.useEffect(() => {
		if (succesfullyAddedNotificationTimelineEvent) {
			setUpdateMessage('');
			setSelectedUpdateType('');
			setAttachmentsList([]);
			dispatch(resetAddNotificationTimelineEvent());
		}
	}, [succesfullyAddedNotificationTimelineEvent]);

	const onUploadFinished = (data) => {
		setFileReadyToUpload(false);
		setAttachmentsList([...attachmentsList, data]);
	};

	const onUpload = (event) => {
		if (event?.target?.files[0]) {
			fileUploadHandler(event.target.files[0], onUploadFinished);
		}
	};

	const onDeleteUpload = (removeIndex) => {
		setAttachmentsList(
			attachmentsList.filter((item, index) => {
				return index !== removeIndex;
			})
		);
	};

	const onCreateUpdate = () => {
		const user = userReducer.get('user');

		const notificationDetail = _createNewDetail(
			updateMessage,
			selectedUpdateType?.name,
			attachmentsList,
			user.id
		);

		dispatch(
			addNotificationTimelineEvent({
				notificationId,
				notificationDetail
			})
		);
	};

	if (
		(!hasLoadedNotificationDetail || isDataEmpty(notificationDetail)) &&
		!notificationDetailError
	) {
		return (
			<Stack direction='row' justifyContent='center'>
				<Skeleton height={260} width='100%' variant='rounded' />
			</Stack>
		);
	}

	if (
		notificationDetailError ||
		!hasLoadedNotificationDetail ||
		notificationDetail?.status === notificationStatusesNamed.closed.id ||
		notificationDetail?.status === notificationStatusesNamed.resolved.id
	) {
		return null;
	}

	return (
		<Restricted to='notifications.notificationDetail.add'>
			<Stack spacing={2} p={1} my={2} component={Paper}>
				<Box pl={1} pt={2}>
					<Typography sx={{ fontSize: '1rem' }}>
						{t('notifications:createUpdate')}
					</Typography>
				</Box>
				<ErrorIndicator error={addNotificationTimelineEventError} />
				<Stack direction='row' gap={3} p={1} flexWrap='wrap'>
					<Stack flexGrow={2}>
						<TextField
							fullWidth
							value={updateMessage}
							onChange={(e) => setUpdateMessage(e.target.value)}
							label={t('notifications:updateMessage')}
							variant='outlined'
						/>
					</Stack>
					<Stack flexGrow={1}>
						<NotificationUpdateTypeSelect
							selectedUpdateType={selectedUpdateType}
							onChangeSelection={setSelectedUpdateType}
							// useSmall
						/>
					</Stack>
				</Stack>
				<Stack
					direction='row'
					gap={3}
					p={1}
					justifyContent='flex-end'
					flexWrap='wrap'
				>
					{/* TODO: Rework the upload attachment functionality */}
					{/* <Box>
						<Button
							startIcon={<AttachmentIcon />}
							variant='outlined'
							fullWidth
							component='label'
						>
							{`${t('common:upload')} ${t('notifications:allowedFileTypes')}`}
							<input
								hidden
								type='file'
								accept='image/png, image/jpeg'
								onChange={onUpload}
							/>
						</Button>
					</Box> */}
					<Box>
						<ContainedLoadingButton
							fullWidth
							onClick={onCreateUpdate}
							loading={isAddingNotificationTimelineEvent}
							disabled={
								!selectedUpdateType ||
								!updateMessage ||
								isLoadingNotificationDetail
							}
						>
							{t('common:submit')}
						</ContainedLoadingButton>
					</Box>
				</Stack>

				<Grid container width={'100%'} spacing={1} ml={1}>
					{attachmentsList.map((assetData, index) => (
						<Grid item xs={12} sm={6} md={3} key={index}>
							<UploadBox component='img' src={assetData} />
							<Button fullWidth onClick={() => onDeleteUpload(index)}>
								{t('common:delete')}
							</Button>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Restricted>
	);
};

NotificationInformation.propTypes = {
	t: PropTypes.func,
	notification: PropTypes.object
};

export default withTranslation(['notifications', 'common'])(
	NotificationInformation
);
