import { Stack } from '@mui/material';
import React from 'react';
import AssignedUserSelect from '../../../notifications/AssignedUserSelect';
import NotificationStatusSelect from '../../../notifications/NotificationStatusSelect';
import { LoadingButton } from '@mui/lab';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { notificationStatusesNamed } from '../../../../constants/notificationStatuses';

const StatusUpdate = ({
	assignedUserId,
	assignedStatus,
	onUpdate,
	isLoading,
	isUpdating
}) => {
	const [updatedAssignedUserId, setUpdatedAssignedUserId] = React.useState(
		assignedUserId ?? 'none'
	);
	const [status, setStatus] = React.useState(assignedStatus ?? 'new');
	const handleUpdate = () => {
		onUpdate({ updatedAssignedUserId, status });
	};

	return (
		<Stack>
			<Stack direction='row' gap={2} flexWrap='wrap' my={2}>
				<Stack flex={1}>
					<AssignedUserSelect
						selectedUser={updatedAssignedUserId}
						onChangeSelection={setUpdatedAssignedUserId}
						overrideLabel={t('notifications:assignedUser')}
						includeAll={false}
						isDisabled={
							isLoading ||
							assignedStatus === notificationStatusesNamed.closed.id ||
							assignedStatus === notificationStatusesNamed.resolved.id
						}
					/>
				</Stack>
				<Stack flex={1}>
					<NotificationStatusSelect
						selectedNotificationStatus={status}
						onChangeSelection={setStatus}
						excludeAll={true}
						isDisabled={isLoading}
					/>
				</Stack>
			</Stack>
			<Stack alignItems='flex-end'>
				<LoadingButton
					loading={isUpdating}
					variant='contained'
					onClick={handleUpdate}
					size='small'
				>
					Update
				</LoadingButton>
			</Stack>
		</Stack>
	);
};

StatusUpdate.propTypes = {
	t: PropTypes.func,
	assignedUserId: PropTypes.number,
	assignedStatus: PropTypes.string,
	onUpdate: PropTypes.func,
	isLoading: PropTypes.bool,
	isUpdating: PropTypes.bool
};

export default withTranslation('notifications')(StatusUpdate);
