import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import registrationStages from '../constants/registrationStages';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const SlyceInformation = ({
	newUserConfiguration,
	onConfigurationChange,
	setCurrentStage,
	onSubmit,
	t
}) => {
	const [username, setUsername] = React.useState(newUserConfiguration.username);
	const [error, setError] = React.useState(false);
	const [helperText, setHelperText] = React.useState('');

	const validateUsername = (value) => {
		const regex = /^[a-zA-Z0-9_]{6,24}$/;
		return regex.test(value);
	};

	const handleUserNameValidation = (e) => {
		const value = e.target.value;
		setUsername(value);
		if (!validateUsername(value)) {
			setError(true);
			setHelperText(
				'Username must be between 6-24 characters and only contain letters, numbers, and underscores'
			);
		} else {
			setError(false);
			setHelperText('');
			onConfigurationChange(value, 'username');
		}
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' alignItems='center'>
				<BoldLargeHeader py={2}>
					{t('registration:registrationForm.newUserRegistration')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='column' justifyContent='center' px={4}>
				<Stack direction='column' alignItems='center' spacing={3} px={4} mt={2}>
					<FormControl sx={{ width: '100%' }}>
						<TextField
							required
							size='small'
							label={t('registration:registrationForm.input.username')}
							value={username}
							onChange={handleUserNameValidation}
							error={error}
							helperText={
								helperText || t('registration:registrationForm.caseInsensitive')
							}
						/>
					</FormControl>
				</Stack>
				<Box my={1} p={2} sx={{ textAlign: 'center' }}>
					<Typography
						sx={{
							fontsize: '.8rem',
							fontWeight: 600,
							alignSelf: 'center'
						}}
					>
						{t('registration:registrationForm.verificationText')}
					</Typography>
				</Box>
				<Stack direction='row' justifyContent='space-between' my={2}>
					<ContainedButton
						onClick={() =>
							setCurrentStage(registrationStages.employeeInformation)
						}
					>
						{t('common:buttons.back')}
					</ContainedButton>
					<ContainedButton
						disabled={newUserConfiguration.username.length < 5}
						onClick={onSubmit}
					>
						{t('registration:registrationForm.register')}
					</ContainedButton>
				</Stack>
			</Stack>
		</Box>
	);
};

SlyceInformation.propTypes = {
	newUserConfiguration: PropTypes.object,
	onConfigurationChange: PropTypes.func,
	setCurrentStage: PropTypes.func,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('common', 'registration')(SlyceInformation);
