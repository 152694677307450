import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
	initialValidationErrors,
	validatePassword
} from '../../validation/password';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import OverlineTextError from '../errors/OverlineTextError';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import SecureValidationInput from '../inputs/SecureValidationInput';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import ContainedButton from '../buttons/ContainedButton';

const PasswordSetupForm = ({
	t,
	doPasswordsMatch,
	isSettingUpPassword,
	newUserPassword,
	setNewUserPassword,
	confirmNewPassword,
	setConfirmNewPassword,
	onSubmitPasswordSetup,
	deAuthUser
}) => {
	const isBlank = (value) => {
		return value.length < 0 || value === undefined || value === '';
	};

	const handleCancelReset = () => {
		deAuthUser();
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' alignItems='center'>
				<BoldLargeHeader py={2}>
					{t('registration:passwordSetup.pageTitle')}
				</BoldLargeHeader>
			</Stack>
			{!doPasswordsMatch && (
				<Stack direction='row' justifyContent='center'>
					<OverlineTextError>
						{t('registration:passwordSetup.passwordMismatch')}
					</OverlineTextError>
				</Stack>
			)}
			<Stack direction='column' justifyContent='center' p={2} mx={4} gap={2}>
				<FormControl>
					<SecureValidationInput
						label={t('registration:passwordSetup.input.newPassword')}
						value={newUserPassword}
						onChange={(value) => setNewUserPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
				<FormControl>
					<SecureValidationInput
						label={t('registration:passwordSetup.input.confirm')}
						value={confirmNewPassword}
						onChange={(value) => setConfirmNewPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>

				<Stack direction='row' justifyContent='space-between' mb={3}>
					<ContainedButton onClick={handleCancelReset}>
						{t('common:buttons.cancel')}
					</ContainedButton>
					<ContainedLoadingButton
						loading={isSettingUpPassword}
						disabled={isBlank(newUserPassword) || isBlank(confirmNewPassword)}
						onClick={onSubmitPasswordSetup}
					>
						{t('common:buttons.submit')}
					</ContainedLoadingButton>
				</Stack>
			</Stack>
		</Box>
	);
};

PasswordSetupForm.propTypes = {
	doPasswordsMatch: PropTypes.bool,
	isSettingUpPassword: PropTypes.bool,
	newUserPassword: PropTypes.string,
	setNewUserPassword: PropTypes.func,
	confirmNewPassword: PropTypes.string,
	setConfirmNewPassword: PropTypes.func,
	onSubmitPasswordSetup: PropTypes.func,
	deAuthUser: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'registration'])(PasswordSetupForm);
