import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router';
import PropTypes from 'prop-types';
import { authActions } from '../containers/Auth';
import Loader from '../components/Loader';
import PrivateRoute from './PrivateRoute';
import PrivateLayoutRoute from './PrivateLayoutRoute';

// PAGES
import AccountPage from '../pages/Account';
import DashboardPage from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import SavedGroupsPage from '../pages/Groups/SavedGroups';
import CreateGroupPage from '../pages/Groups/CreateGroup';
import ManageGroupPage from '../pages/Groups/ManageGroup';
import LoginPage from '../pages/Login';
import MultiFactorAuthentication from '../pages/MultiFactorAuthentication';
import OpportunitiesPage from '../pages/Representments/Opportunities';
import NotificationDetailsPage from '../pages/NotificationDetails';
import NotificationsPage from '../pages/Notifications';
import PasswordResetPage from '../pages/PasswordReset';
import PasswordSetupPage from '../pages/PasswordSetup';
import PostLogin from '../pages/PostLogin';
import Registration from '../pages/Registration';
import RepresentmentPage from '../pages/Representment';
import RepresentmentViewerPage from '../pages/RepresentmentViewer';
import RuleListPage from '../pages/RuleManagement/RuleList';
import EditRulePage from '../pages/RuleManagement/EditRule';
import BuildRule from '../pages/RuleManagement/BuildRule';
import UsersPage from '../pages/Users';
import ResolvedCasesPage from '../pages/Representments/Resolved';
import NotChallengedPage from '../pages/Representments/NotChallenged';
import InsightReportsPage from '../pages/Insights';

// REPORTS
import NotificationRuleReport from '../pages/reports/NotificationRuleReport';
import TableauReportPage from '../pages/reports/TableauReport';

// POP
import PopTransactionsPage from '../pages/pop/PopTransactions';

const AppRouter = (props) => {
	const { isAuthed, hasCheckedUserSession, authUserSession } = props;

	React.useEffect(() => {
		if (!isAuthed && !hasCheckedUserSession) {
			authUserSession();
		}
	}, [isAuthed, hasCheckedUserSession]);

	if (!hasCheckedUserSession) {
		return <Loader />;
	}

	return (
		<Router>
			<Routes>
				<Route path='/login' element={<LoginPage />} />
				<Route path='/post-login' element={<PostLogin />} />
				<Route path='/registration' element={<Registration />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path='/password-reset' element={<PasswordResetPage />} />
				<Route
					path='/password-setup'
					element={<PrivateRoute isAuthed={isAuthed} />}
				>
					<Route path='' element={<PasswordSetupPage />} />
				</Route>
				<Route
					path='/multi-factor-auth'
					element={<PrivateRoute isAuthed={isAuthed} />}
				>
					<Route path='' element={<MultiFactorAuthentication />} />
				</Route>
				<Route path='/' element={<PrivateLayoutRoute isAuthed={isAuthed} />}>
					<Route path='account' element={<AccountPage />}>
						<Route path=':modalFunction' element={<AccountPage />} />
					</Route>
					<Route path='dashboard' element={<DashboardPage />} />
					<Route path='notifications' element={<NotificationsPage />} />
					<Route
						path='notifications/:notificationId'
						element={<NotificationDetailsPage />}
					/>
					<Route path='representments'>
						<Route
							path='build/:representmentCaseNumber'
							element={<RepresentmentPage />}
						/>
						<Route
							path='view/:chargebackId'
							element={<RepresentmentViewerPage />}
						/>
						<Route path='opportunities' element={<OpportunitiesPage />} />
						<Route path='not-challenged' element={<NotChallengedPage />} />
						<Route path='resolved' element={<ResolvedCasesPage />} />
					</Route>
					<Route path='groups' element={<SavedGroupsPage />} />
					<Route path='groups/create' element={<CreateGroupPage />} />
					<Route path='groups/:groupId/manage' element={<ManageGroupPage />} />
					<Route path='rules' element={<RuleListPage />} />
					<Route path='rules/edit/:userRuleId' element={<EditRulePage />} />
					<Route path='rules/build' element={<BuildRule />} />
					<Route path='users' element={<UsersPage />} />
					<Route path='reports'>
						<Route
							path='1815860b-108d-4167-9b61-a552f001d6c1'
							element={<NotificationRuleReport />}
						/>

						<Route path=':tableauReportId' element={<TableauReportPage />} />
					</Route>
					<Route
						path='insights/:insightReportId/views/:tableauReportId/:tableauReportName'
						element={<InsightReportsPage />}
					/>
					<Route path='pop'>
						<Route path='transactions' element={<PopTransactionsPage />} />
					</Route>
					<Route path='/' element={<Navigate to='dashboard' />} />
				</Route>
			</Routes>
		</Router>
	);
};

AppRouter.propTypes = {
	authUserSession: PropTypes.func,
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	hasCheckedUserSession: PropTypes.bool,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isAuthed: state.authReducer.get('isAuthed'),
		hasCheckedUserSession: state.authReducer.get('hasCheckedUserSession'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isLoadingUser: state.userReducer.get('isLoadingUser')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUserSession: (payload) => {
			dispatch(authActions.authUserSession(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(AppRouter));
