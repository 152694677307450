import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ErrorIndicator from '../errors/ErrorIndicator';

export const SubmitRegistrationError = ({ t }) => {
	const navigate = useNavigate();

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				px={4}
				pb={2}
			>
				<BoldLargeHeader py={2} textAlign='center'>
					{t('registrationForm.submitRegistrationError.header')}
				</BoldLargeHeader>
				<ErrorIndicator
					error={t('registrationForm.submitRegistrationError.errorSubmitting')}
				/>

				<Typography paragraph sx={{ textAlign: 'center' }}>
					{t('registrationForm.submitRegistrationError.contactSlyce360')}
				</Typography>
				<ContainedButton
					bgcolor='primary.alt'
					onClick={() => navigate('/login')}
				>
					{t('registrationForm.backToLogin')}
				</ContainedButton>
			</Stack>
		</Box>
	);
};

SubmitRegistrationError.propTypes = {
	t: PropTypes.func
};

export default withTranslation('registration')(SubmitRegistrationError);
