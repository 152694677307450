import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const ValidationInput = ({
	label,
	onChange,
	value,
	onEnterListener,
	validator,
	initialValidationErrors,
	displayFormatter,
	helperText
}) => {
	const [validationErrors, setValidationErrors] = React.useState(
		initialValidationErrors || []
	);
	const handleValidation = (value) => {
		const validationErrors = validator ? validator(value) : [];

		if (validationErrors.length) {
			setValidationErrors(validationErrors);
		} else {
			setValidationErrors([]);
		}

		onChange(value);
	};

	return (
		<TextField
			required
			error={value === '' || !value ? null : validationErrors.length !== 0}
			onChange={(e) => handleValidation(e.target.value)}
			sx={{ textDecoration: 'none!important' }}
			label={label}
			variant='outlined'
			onKeyDown={(event) => {
				if (event.key === 'Enter' && onEnterListener) {
					onEnterListener();
				}
			}}
			size='small'
			type={'text'}
			value={displayFormatter ? displayFormatter(value) : value}
			helperText={helperText || null}
		/>
	);
};

ValidationInput.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	showTooltip: PropTypes.bool,
	value: PropTypes.string,
	onEnterListener: PropTypes.func,
	validator: PropTypes.func,
	initialValidationErrors: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	displayFormatter: PropTypes.func,
	helperText: PropTypes.string
};

export default ValidationInput;
