import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import OverlineTextError from '../errors/OverlineTextError';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export const InvitationLoader = (props) => {
	const { validatedInvitationId, validateInvitationError, t } = props;

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' alignItems='center' px={4}>
				<BoldLargeHeader py={2} textAlign='center'>
					{t('registrationForm.newUserRegistration')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='row' justifyContent='center' p={2} mx={4}>
				{validateInvitationError ? (
					<OverlineTextError>{validateInvitationError}</OverlineTextError>
				) : null}
				{!validatedInvitationId && !validateInvitationError ? (
					<CircularProgress />
				) : null}
			</Stack>
		</Box>
	);
};

InvitationLoader.propTypes = {
	validatedInvitationId: PropTypes.string,
	validateInvitationError: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('registration')(InvitationLoader);
