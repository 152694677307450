import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import OverlineTextError from '../errors/OverlineTextError';
import {
	initialValidationErrors,
	validatePassword
} from '../../validation/password';

import SecureValidationInput from '../inputs/SecureValidationInput';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import ContainedButton from '../buttons/ContainedButton';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const PasswordResetForm = ({
	t,
	passwordResetError,
	isResettingPassword,
	temporaryPassword,
	setTemporaryPassword,
	newUserPassword,
	setNewUserPassword,
	confirmNewPassword,
	setConfirmNewPassword,
	onSubmitPasswordReset,
	doPasswordsMatch,
	deAuthUser
}) => {
	const isBlank = (value) => {
		return value.length < 0 || value === undefined || value === '';
	};

	const handleCancelReset = () => {
		deAuthUser();
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				ml={1}
				mt={0.5}
			>
				<BoldLargeHeader py={2}>{t('passwordReset.title')}</BoldLargeHeader>
			</Stack>
			{passwordResetError && (
				<Stack direction='row' alignItems='center' my={1}>
					<OverlineTextError>{passwordResetError}</OverlineTextError>
				</Stack>
			)}
			{!doPasswordsMatch && (
				<OverlineTextError>
					{t('passwordReset.passwordMismatch')}
				</OverlineTextError>
			)}

			<Stack direction='column' justifyContent='center' p={2} mx={4} gap={2}>
				<FormControl sx={{ width: '100%' }}>
					<TextField
						label={t('passwordReset.input.temporaryPassword')}
						size='small'
						value={temporaryPassword}
						onChange={(e) => setTemporaryPassword(e.target.value)}
						type='text'
						variant='outlined'
						sx={{ mb: 1 }}
					/>
				</FormControl>
				<FormControl sx={{ width: '100%' }}>
					<SecureValidationInput
						label={t('passwordReset.input.newPassword')}
						value={newUserPassword}
						onChange={(value) => setNewUserPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>
				<FormControl sx={{ width: '100%' }}>
					<SecureValidationInput
						label={t('passwordReset.input.confirm')}
						value={confirmNewPassword}
						onChange={(value) => setConfirmNewPassword(value)}
						initialValidationErrors={initialValidationErrors}
						validator={validatePassword}
						t={t}
					/>
				</FormControl>

				<Stack direction='row' justifyContent='space-between' gap={2}>
					<ContainedButton fullWidth onClick={handleCancelReset}>
						{t('passwordReset.cancel')}
					</ContainedButton>
					<ContainedLoadingButton
						loading={isResettingPassword}
						disabled={
							isBlank(temporaryPassword) ||
							isBlank(newUserPassword) ||
							isBlank(confirmNewPassword)
						}
						onClick={onSubmitPasswordReset}
					>
						{t('passwordReset.resetPassword')}
					</ContainedLoadingButton>
				</Stack>
			</Stack>
		</Box>
	);
};

PasswordResetForm.propTypes = {
	passwordResetError: PropTypes.string,
	isResettingPassword: PropTypes.bool,
	temporaryPassword: PropTypes.string,
	setTemporaryPassword: PropTypes.func,
	newUserPassword: PropTypes.string,
	setNewUserPassword: PropTypes.func,
	confirmNewPassword: PropTypes.string,
	setConfirmNewPassword: PropTypes.func,
	onSubmitPasswordReset: PropTypes.func,
	doPasswordsMatch: PropTypes.bool,
	deAuthUser: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('registration')(PasswordResetForm);
