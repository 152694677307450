import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ConfirmationForm = (props) => {
	const { t, hasCompletedPasswordSetup, userHasBeenLoaded } = props;

	const navigate = useNavigate();

	return (
		<Box sx={{ width: 400 }}>
			{hasCompletedPasswordSetup && userHasBeenLoaded ? (
				<>
					<Box>
						<Stack direction='row' justifyContent='center' alignItems='center'>
							<BoldLargeHeader py={2} textAlign='center'>
								{t('passwordSetup.success')}
							</BoldLargeHeader>
						</Stack>
						<Stack direction='row' justifyContent='center' alignItems='center'>
							<Typography py={2}>{t('passwordSetup.completed')}</Typography>
						</Stack>
					</Box>
					<Stack direction='row' justifyContent='center' mb={3}>
						<ContainedButton
							disabled={!hasCompletedPasswordSetup || !userHasBeenLoaded}
							onClick={() => {
								navigate('/dashboard');
							}}
						>
							{t('passwordSetup.continueToDashboard')}
						</ContainedButton>
					</Stack>
				</>
			) : (
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					p={3}
				>
					<Typography py={2}>{t('passwordSetup.pleaseWait')}</Typography>
					<CircularProgress />
				</Stack>
			)}
		</Box>
	);
};

ConfirmationForm.propTypes = {
	hasCompletedPasswordSetup: PropTypes.bool,
	userHasBeenLoaded: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('registration')(ConfirmationForm);
