import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ConfirmationForm = ({ t, deAuthUser }) => {
	return (
		<Stack gap={3}>
			<Stack direction='row' justifyContent='center'>
				<BoldLargeHeader pt={2} textAlign='center'>
					{t('passwordReset.success')}
				</BoldLargeHeader>
			</Stack>
			<Typography variant='body1' textAlign='center'>
				{t('passwordReset.completed')}
			</Typography>

			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedButton onClick={deAuthUser}>
					{t('passwordReset.backToLogin')}
				</ContainedButton>
			</Stack>
		</Stack>
	);
};

ConfirmationForm.propTypes = {
	deAuthUser: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('registration')(ConfirmationForm);
