import React from 'react';
import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';

const ContainedLoadingButton = ({
	bgcolor,
	children,
	disabled,
	loading,
	onClick,
	sx,
	fullWidth
}) => {
	return (
		<LoadingButton
			sx={{ bgcolor, ...sx }}
			color='primary'
			disabled={disabled}
			loading={loading}
			onClick={onClick}
			fullWidth={fullWidth}
			variant='contained'
		>
			{children}
		</LoadingButton>
	);
};

ContainedLoadingButton.propTypes = {
	bgcolor: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.object,
	fullWidth: PropTypes.bool
};

export default ContainedLoadingButton;
