import React from 'react';
import PropTypes from 'prop-types';
import deepCopy from '../../utilities/deepCopy';
import AvatarUpload from './RegistrationStages/AvatarUpload';
import registrationStages from './constants/registrationStages';
import EmployeeInformation from './RegistrationStages/EmployeeInformation';
import SlyceInformation from './RegistrationStages/SlyceInformation';

import Box from '@mui/material/Box';

export const RegistrationForm = ({
	newUserConfiguration,
	setNewUserConfiguration,
	onSubmit
}) => {
	const [currentStage, setCurrentStage] = React.useState(
		registrationStages.employeeInformation
	);

	const onConfigurationChange = (value, key) => {
		const updatedNewUserConfiguration = deepCopy(newUserConfiguration);

		updatedNewUserConfiguration[key] = value;

		setNewUserConfiguration(updatedNewUserConfiguration);
	};

	return (
		<Box>
			{currentStage === registrationStages.employeeInformation ? (
				<EmployeeInformation
					newUserConfiguration={newUserConfiguration}
					onConfigurationChange={onConfigurationChange}
					setCurrentStage={setCurrentStage}
				/>
			) : null}
			{currentStage === registrationStages.slyceInformation ? (
				<SlyceInformation
					newUserConfiguration={newUserConfiguration}
					onConfigurationChange={onConfigurationChange}
					setCurrentStage={setCurrentStage}
					onSubmit={onSubmit}
				/>
			) : null}
			{currentStage === registrationStages.avatarUpload ? (
				<AvatarUpload
					newUserConfiguration={newUserConfiguration}
					onConfigurationChange={onConfigurationChange}
					setCurrentStage={setCurrentStage}
				/>
			) : null}
		</Box>
	);
};

RegistrationForm.propTypes = {
	isRegistering: PropTypes.bool,
	newUserConfiguration: PropTypes.object,
	setNewUserConfiguration: PropTypes.func,
	onSubmit: PropTypes.func
};

export default RegistrationForm;
