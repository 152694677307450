import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
	getCurrentTimestamp,
	formatDateForDisplay
} from '../../../../utilities/dateWrapper';

import Reports from './components/Reports';
import My360 from './components/My360';
import Representments from './components/Representments';

import Notifications from './menuItems/Notifications';
import Users from './menuItems/Users';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';

const SideNavigation = (props) => {
	const { t } = props;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overflowX: 'auto',
				minWidth: 250,
				maxWidth: 250,
				bgcolor: 'common.offWhite'
			}}
		>
			<Stack spacing={3}>
				<Box>
					<Divider flexItem />
					<My360 />
					<Divider flexItem />
				</Box>
				<Reports />
				<Representments />
			</Stack>
			<Box mt={3}>
				<MenuList>
					<Notifications />
					<Users />
				</MenuList>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					color: 'secondary.alt',
					flex: 1
				}}
			>
				<Typography variant='caption' pb={2}>
					{`\u00A9 ${formatDateForDisplay(getCurrentTimestamp(), 'YYYY')} ${t(
						'copywrite'
					)}`}
				</Typography>
			</Box>
		</Box>
	);
};

SideNavigation.propTypes = {
	t: PropTypes.func
};

export default withTranslation('layout')(SideNavigation);
